import React from "react";
import facebookIcon from "../images/Icon awesome-facebook-square.svg";
import linkedinIcon from "../images/Icon awesome-linkedin.svg";
import twitterIcon from "../images/Icon awesome-twitter-square.svg";

export default function SocialIcons() {
    return (
        <div className="social">
            <a href="https://www.facebook.com/enerjoy.co" target={"_blank"}><img src={facebookIcon}
                                                                                 alt="Our Facebook page"/></a>
            <a href="https://www.linkedin.com/company/enerjoy/" target={"_blank"}><img src={linkedinIcon}
                                                                                       alt="Our LinkedIn page"/></a>
        </div>
    );
}