/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react";
import PropTypes from "prop-types";
import {useStaticQuery, graphql, Link} from "gatsby";
import loadable from "@loadable/component";

import Header from "./header";
import "./layout.css";
import SocialIcons from "./SocialIcons";
import footerLogoImg from "../images/logo footer.png";

const VendorLoader = loadable(() => import("./vendorLoader"));

const Layout = ({children, location}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <div id="full_wrapper">
            <VendorLoader/>
            <Header siteTitle={data.site.siteMetadata.title} location={location}/>
            <div className="main">
                {children}
                <div className="push"/>
            </div>
            <footer>
                <div className="wrapper cf">
                    <div className="col_5 social_wrap">
                        <a href="#" className="logo_footer"><img src={footerLogoImg} alt="logo"/></a>
                        <SocialIcons/>
                        <p>All right reserved 2020</p>
                    </div>
                    <div className="col_5">
                        <h3>Product</h3>
                        <ul>
                            <li><Link to="/features">Overview</Link></li>
                            <li><Link to="/platform#how-it-works">How it works</Link></li>
                            <li><Link to="/platform#motivational-insights">Psychological team</Link></li>
                            <li><Link to="/platform#sales-managers-love-us">Sales managers love us</Link></li>
                        </ul>
                    </div>
                    <div className="col_5 company">
                        <h3>Company</h3>
                        <ul>
                            <li><Link to="/about">About us</Link></li>
                            <li><Link to="/book-a-demo">Contact us</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/terms-of-service">Terms of Service</Link></li>
                        </ul>
                    </div>
                    <div className="col_5 knowledge">
                        <h3>Knowledge Center</h3>
                        <ul>
                            <li><Link to="/knowledge-center/how-personal-profiling-can-help-boost-your-sales-team">How
                                personal profiling can boost sales</Link></li>
                            <li><Link to="/knowledge-center/the-secret-solution-for-a-rep-with-poor-performance">The
                                secret solution</Link></li>
                        </ul>
                    </div>
                    <div className="col_5 contact_us">
                        <h3><Link to={'/book-a-demo'}>Contact us</Link></h3>
                        <ul>
                            <li><h4>US Office</h4></li>
                            <li><p>729 Ocean Parkway Brooklyn NY 1123</p></li>
                            <li><a href="mailto:info@enerjoy.co">info@enerjoy.co</a></li>
                        </ul>
                        <ul>
                            <li><h4>Israel Office</h4></li>
                            <li><p>HaSheizaf St 4, Ra'anana, 6th Floor</p></li>
                            <li><a href="mailto:info@enerjoy.co">info@enerjoy.co</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
