import {Link} from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import logoImg from '../images/logo.png';
import SocialIcons from "./SocialIcons";

const Header = ({siteTitle, location}) => (
    <header>
        <div className="wrapper cf">
            <Link to={'/'} className="logo">
                <img src={logoImg} alt="logo"/>
            </Link>
            <Link to={'/book-a-demo'} className="book_demo book_demo_mobile">Book a demo</Link>
            <a href="javascript:void(0)" className="hamburger"><span/></a>
            <div className="mobile_menu">
                <a href="#" className="mo_logo"><img src={logoImg} alt=""/></a>
                <nav>
                    <ul>
                        <li><Link to={'/platform'}
                                  className={location && location.pathname === '/platform' ? 'active' : ''}>Platform</Link>
                        </li>
                        <li><Link to={'/features'}
                                  className={location && location.pathname === '/features' ? 'active' : ''}>Features</Link>
                        </li>
                        <li><Link to={'/about'} className={location && location.pathname === '/about' ? 'active' : ''}>About
                            us</Link></li>
                        <li><Link to={'/knowledge-center'}
                                  className={location && location.pathname === '/knowledge-center' ? 'active' : ''}>Knowledge
                            center</Link></li>
                    </ul>
                </nav>
                <Link to={'/book-a-demo'} className="book_demo">Book a demo</Link>
                <SocialIcons/>
            </div>
        </div>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
